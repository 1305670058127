import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Minislider from "../components/minislider";
//import Svgsprite from "../components/svgsprite";
import Youtube from "../components/youtube";
//import store from "../images/svg/store.svg";
//import play from "../images/svg/play.svg";

const IndexPage = () => (
  <Layout>
    <SEO title="Guard app" />
    <section data-side="guard" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          Boatpilot Guard <span>remote monitor</span>
        </h2>
        <p>
          The Boatguard application from Boatpilot is designed to receive
          telemetry from the yacht and remotely monitor all systems connected to
          the BoatGod module. The application is available on IOS and Android
          platforms.
        </p>
        <p>
          It visualizes all the information from your boat's sensors coming from
          the BoatGod module to the cloud. This allows you to remotely monitor
          the condition of the yacht and all its systems, since the BoatGod
          module transmits information from the NMEA sensors, as well as
          additional sensors from the BoatPilot.
        </p>
        {/* <p>
          <a style={{ marginRight: `1em` }} href="/">
            <Svgsprite
              svgclass="storebtn"
              name={store.id}
              vbox={store.viewBox}
            />
          </a>
          <a href="/">
            <Svgsprite svgclass="storebtn" name={play.id} vbox={play.viewBox} />
          </a>
        </p> */}
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "guard_1.jpg",
            "guard_2.jpg",
            "guard_3.jpg",
            "guard_4.jpg",
            "guard_5.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube
          tubeid="lsEGtPeWJ-w"
          posterimagename="tubeposter_chartplotter.jpg"
        />
      </div>
    </section>
    <section
      data-side="for what"
      className="contentbox wrap --fullw --dark --novideo"
    >
      <div className="textcontent --max80">
        <h2>
          Use cases <span>for the app</span>
        </h2>

        <p>Cases, when you can use BoatGuard are quite broad:</p>

        <ul className="list">
          <li>
            it is used to control humidity and leaks during parking in the
            marina, as well as to monitor the level of battery charge. These two
            parameters are of paramount importance during winter parking. Since
            any leakage is a danger of flooding the boat, and deep discharge of
            the battery is the main reason for the premature failure of the bank
            power and its subsequent pricey replacement.
          </li>
          <li>
            it is used if you hire a skipper to deliver your boat and want to be
            sure that he is doing his job professionally and carefully, without
            violating the rules of boat operation{" "}
          </li>
          <li>
            it is used to receive alerts. You can get any data, from filling the
            black tank to leaking gas from the cylinder. And during navigation,
            you can set the required number of alerts to significantly improve
            the safety of navigation. For example,
          </li>
          <li>wind reinforcement alert</li>
          <li>anchor drift alert</li>
          <li>depth reduction alert </li>
          <li>alert to approach the coastline</li>
          <li>dangerous approach trajectory alert (by AIS)</li>
          <li>alert to critical charge level of batteries</li>
          <li>water leakage alert </li>
        </ul>

        <h3>No extra costs</h3>
        <p>
          The application is included in a single premium subscription from
          BoatPilot. It incorporates cloud services and remote telemetry, as
          well as additional features for chart plotter, significantly
          increasing the usability of the product and intended for active
          sailors.
        </p>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
